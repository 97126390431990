import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "How do I create a time lapse video from my photo series?",
  "path": "/Frequently_Asked_Question/Time_lapse/",
  "dateChanged": "2020-12-03",
  "author": "Mike Polinowski",
  "excerpt": "Which software can I use to edit my video recordings?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - How do I create a time lapse video from my photo series?' dateChanged='2020-12-03' author='Mike Polinowski' tag='INSTAR IP Camera' description='Which software can I use to edit my video recordings?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Time_lapse/' locationFR='/fr/Frequently_Asked_Question/Time_lapse/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <p><strong parentName="p">{`Q`}</strong>{`: Your cameras have the function to take a series of photos. How can I combine these individual shots into a time-lapse video?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: You can do this for example with these two free software packages `}<strong parentName="p">{`iMovie`}</strong>{` (`}<em parentName="p">{`macOS`}</em>{`) or the `}<strong parentName="p">{`Time Lapse Creator`}</strong>{` (`}<em parentName="p">{`Windows 10`}</em>{`).`}</p>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <p>{`First go to the Microsoft Store and install the `}<strong parentName="p">{`Time Lapse Creator`}</strong>{` programme from there:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "872px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cc8d404617878963355661b672c54304/65654/Time_Lapse_Creator_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACzklEQVQ4y02Ty44bRRSG/RQRQoAcPHZfqqv6Yve07e4ej/tiu21ryABihCOSLEaQZJlFRECBBBQQgihcgkAIFkhhhxBSwiKIgQUsEAukSLwBbFjzAB/qmngmi09Hqu7666/zn2qkaYrn+ziOo5FSIoTQ2LaNHwRkZUFelhTTqaaczY7wuiHKdVFK0e/3afTCkJ6v8H0f13V1rYVq4Va7zerULr99f5dfvv2OBwcHPPjpgD/v/8jvd+/xx70f2JuMsM0NXM9D2IKGme5wcucSw35EHMcMh0PiJCHs9XCDgMrrcuXSdcpRSWxbbJoWqtli47HHcU+coP/8NZIXLhAFLrZwaIh0QWt+nmQ4YDwes7W1RZZl2ql0XSJHkocxm4ZN+HSLzbZBZDr0bUVsWHjPXibZPcfmWtCxDPqBZDabaXq9HsJx2EoT0jQhThMG8YA4jZH1IZ6nD3JchVP3zmzimG29R9SC3W6X/Zdf4crrb/Dqa1e5cPEi/UFMXO6QzXfJp0u2q+fIqlO4SqGk1AFopEK5nuYwUEUjzzM+uv0Zn37+JVevvc0XX31NnueEwxFBOKBj2hjCwxCudrCeBkc7EkeT8YhgzjvvfcD9n3/lxrvv8+b1GywWcyZFzryqNNPplMV8zmxWUc3nLJcLqqpiuVzqNtXfa8d2nXIQ+Ly4WmnRmx9+wpmzZ/WVt4sZRZ5TZBnTsiQfj5lMSoqioCwP65ooih66lTRqu0mS6GvWKYc6FIkI+gi3i3ADbBVg11UqhLCPBn/NcQscGqdXK765c4dxMcG0LKSwkNGIp976iydv/ot/+x+MW3/zxMf/0Tl3C9dqHQaxDuYhh6KSRu1qf39f264XlZIozyec7REtXyJ55gy96R7WaBcn2kY5AqmUDmLNsaBDo9Pp0Gw2MU1TPznLsjTSaqOsNtLq4Bgb2O2T2EYbyzr+51HqvbXD/wE+gccAtU4dUQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc8d404617878963355661b672c54304/e4706/Time_Lapse_Creator_01.avif 230w", "/en/static/cc8d404617878963355661b672c54304/d1af7/Time_Lapse_Creator_01.avif 460w", "/en/static/cc8d404617878963355661b672c54304/749ce/Time_Lapse_Creator_01.avif 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cc8d404617878963355661b672c54304/a0b58/Time_Lapse_Creator_01.webp 230w", "/en/static/cc8d404617878963355661b672c54304/bc10c/Time_Lapse_Creator_01.webp 460w", "/en/static/cc8d404617878963355661b672c54304/a8a2c/Time_Lapse_Creator_01.webp 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc8d404617878963355661b672c54304/81c8e/Time_Lapse_Creator_01.png 230w", "/en/static/cc8d404617878963355661b672c54304/08a84/Time_Lapse_Creator_01.png 460w", "/en/static/cc8d404617878963355661b672c54304/65654/Time_Lapse_Creator_01.png 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cc8d404617878963355661b672c54304/65654/Time_Lapse_Creator_01.png",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then click on `}<strong parentName="p">{`Import Folder`}</strong>{` in the software interface and select the location where you saved your photo series:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c72d2f9a59dbc39767606272ec23fb0b/1b1d5/Time_Lapse_Creator_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC7ElEQVQ4y42UW28TRxiG7Y33vOtd7yG2jBKo7RiapEkImMYGhAQOROoNFfQgRaoKtAolUC7gigAihqhYalEUDFcIEBJIXKAIyiE0PaSotFL/1FPNpA7B3HDx7q5m9nvmnW/e3UQcx/T09JDNZnFdl7TnYaVdXN/HcBxsz8N0HBTLYothstkwSVkmhq5jGAaO46zWpdPkcjkSYiCIc8TZPJkgIBdGlIKQcjZHIQgpRjHFTIDv+xwr9vFNoQ8ziigVCuTzeQlsKwxDEolEQlzwPQ8/DNnguOzyA6pRNzvjLLW4m51hxGgU8/Hu3VRqVTbk89TrdQYGBmjXCylK1yowmUxKy45tY7suiqZhuS66bWO6DpplkdI1LNMkCkMsy0LTNEzTlLJtWzoU4xKYSqUkUEIdR/ZG9Cht29K53E4QrBWJeQER9yAI5LOqqqiqtgpsT4g+CTmuK19KGDqu72H+Py+a39XVJYs9cVimuWZAcMS4BIrBwcFBhoaGGB4eZnO5THcuR1012Du6jXQQsHVkhCiK6O/vl4lY37u21oCKosgTEi4ymQy6rpNUFCq2Q/+mjaR9j2KhIIEiYiJqipKUdeuBqZT65pTX6+Cnh1i4/hPNVpP51lVu3Gzy47XLzDZmOHVqimNHvmLuyhwT+/fTPlQJVDuA7RWnT57g5fJTlpaf8GL5MSt//8pvfy6x+PMiC/NNDh2ocfvWXY4e+fatOrUT2F7p6rV5Jicnudg4x6Oni7z6Z4W//n3Ns6VnzJydZnzsQ1oLLaaOn34/4NHjJ9m+bTuzjQv88up37t6/w72HD2j+cJ7PJqrUxwZpzS8wdeLMW8B3ttzWaGUHI8Mf0Zib5fkfK1yaneG776c5fHCCLw5/wtiuKjMXGnz+5dfvOhS5EnkSuRISOezp7aVcLlHZUWHPvnFqtTGq1Qql0iY2Fgtkez+g2LeFMIo7YqORMAxT/mFEXIREdMTnJeCdzpMdMREShoSES103+A/CxqJQW40PAwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c72d2f9a59dbc39767606272ec23fb0b/e4706/Time_Lapse_Creator_02.avif 230w", "/en/static/c72d2f9a59dbc39767606272ec23fb0b/d1af7/Time_Lapse_Creator_02.avif 460w", "/en/static/c72d2f9a59dbc39767606272ec23fb0b/5579a/Time_Lapse_Creator_02.avif 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c72d2f9a59dbc39767606272ec23fb0b/a0b58/Time_Lapse_Creator_02.webp 230w", "/en/static/c72d2f9a59dbc39767606272ec23fb0b/bc10c/Time_Lapse_Creator_02.webp 460w", "/en/static/c72d2f9a59dbc39767606272ec23fb0b/21dbd/Time_Lapse_Creator_02.webp 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c72d2f9a59dbc39767606272ec23fb0b/81c8e/Time_Lapse_Creator_02.png 230w", "/en/static/c72d2f9a59dbc39767606272ec23fb0b/08a84/Time_Lapse_Creator_02.png 460w", "/en/static/c72d2f9a59dbc39767606272ec23fb0b/1b1d5/Time_Lapse_Creator_02.png 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c72d2f9a59dbc39767606272ec23fb0b/1b1d5/Time_Lapse_Creator_02.png",
              "alt": "Windows Time Lapse Creator",
              "title": "Windows Time Lapse Creator",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then all you have to do is press save and you can then specify a location for the finished time-lapse recording.`}</p>
    <h2 {...{
      "id": "macos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#macos",
        "aria-label": "macos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`macOS`}</h2>
    <p>{`Start iMovie and click the Plus icon to start a new project:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/0d98f/01_iMovie_Neues_Projekt.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABYUlEQVQoz42Ry0rDQBSG8wZiqLUzk0yTtiJp7hExDU0vighCF1JMQWnaUrsqpStxLW4ENz6Na8EH+yUjDVLoZfFz5nDmfDPn/FLU8OD5HjzvT67rwvd9+EGA4CxAGIbo9XpI0xRRFMG27fzeSlnuOA6azSYkQigIISCUiMgUhuOjIuSDQ5BiCaRUAqMUjDEhRVHyuC5KKaSTWg2maaJer8OyLXCVI769QrKYIJlPMFsuEYQNASmXy+Ccb5SmaZCyEVqtFtrtNjqdDqpaFZeDa9wthhgs5kimI3hRLIDbYKqqigcly7IQxzG63a6A6pqGm34fo9kYbniOl7dX9B+GKBQKW3+YAw3DEIvPlC2dKwzj5TM+Pt+RPN7j6/sH6dMMsiyLkXYCs0OlUhE71HVdFLim49QwcRGGsBwHTFHypp3AVZK5tGpQhWsMlBDhHFdVUdsbuF7Y1LCXKfs074L9B/4CoZwl/emDefsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/deff1a8894f0fdf0ec2245510f90c9a5/e4706/01_iMovie_Neues_Projekt.avif 230w", "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/d1af7/01_iMovie_Neues_Projekt.avif 460w", "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/7f308/01_iMovie_Neues_Projekt.avif 920w", "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/8faeb/01_iMovie_Neues_Projekt.avif 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/deff1a8894f0fdf0ec2245510f90c9a5/a0b58/01_iMovie_Neues_Projekt.webp 230w", "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/bc10c/01_iMovie_Neues_Projekt.webp 460w", "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/966d8/01_iMovie_Neues_Projekt.webp 920w", "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/63399/01_iMovie_Neues_Projekt.webp 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/deff1a8894f0fdf0ec2245510f90c9a5/81c8e/01_iMovie_Neues_Projekt.png 230w", "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/08a84/01_iMovie_Neues_Projekt.png 460w", "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/c0255/01_iMovie_Neues_Projekt.png 920w", "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/0d98f/01_iMovie_Neues_Projekt.png 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/deff1a8894f0fdf0ec2245510f90c9a5/c0255/01_iMovie_Neues_Projekt.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select Film to combine your photos into a movie:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/57c819d0822dfccac6f0ab4d061989e2/302a4/02_iMovie_Neues_Projekt_Film.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB10lEQVQoz52SW2sTQRiG8we8qGaTPUx2c5AStbtLE5M02aw5tKUtJkouglf1wmJFEo+gQQWpNGksaYso1qoXglQU/AHFS8H/9UgWE7G2iXjxMDDfvM983zC+TNrCtC0s6xC2jWVbJBIJUqkUxWKRWq1GvV736qZp/p2xLHyKoqGpGpr2GyEEciCIdNKPGlQIiRCqomAYxrB+HL5IJEIsFhsSjUYJhUJMO1lyi3M4S2XmKxVmCiVvvy/Vdf1YfLZt47ouTi5HPp8nk0kjhM7qRoPbO4+496LL0+1Nlhu3MCL9y8Ro4ZRl47gXKJTLOK5LMpVG6Ab5yyVMJ8v5YpF0yWFmfpHJ+BmEpnnBfrdHCnNmnIV8lupsmQXXoZBKEhYarW6PZ2sPmF2ao9Vuc/D9B2KEaCi80/tKZ2OL3c0OL593eb+zxbVLF7nxZJ219ccsX63z5sNHXr17i6oJ9HHCb502+9s99vde83lvl4Mvn7h/fYUTE6c4HZ/CnE4yefYckqwMQ6O69HWuVLi7usLNRpNms8nDVotKtYosyxh6CE1VvXczfonGjjwhq0iBIAFJQpIk/H4/iiwfeXiczBOGDcP7WwPC4bC3DsL/IvlDOBjjMP8j6wt/Aof+dYyJtOZTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/57c819d0822dfccac6f0ab4d061989e2/e4706/02_iMovie_Neues_Projekt_Film.avif 230w", "/en/static/57c819d0822dfccac6f0ab4d061989e2/d1af7/02_iMovie_Neues_Projekt_Film.avif 460w", "/en/static/57c819d0822dfccac6f0ab4d061989e2/7f308/02_iMovie_Neues_Projekt_Film.avif 920w", "/en/static/57c819d0822dfccac6f0ab4d061989e2/d8ca6/02_iMovie_Neues_Projekt_Film.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/57c819d0822dfccac6f0ab4d061989e2/a0b58/02_iMovie_Neues_Projekt_Film.webp 230w", "/en/static/57c819d0822dfccac6f0ab4d061989e2/bc10c/02_iMovie_Neues_Projekt_Film.webp 460w", "/en/static/57c819d0822dfccac6f0ab4d061989e2/966d8/02_iMovie_Neues_Projekt_Film.webp 920w", "/en/static/57c819d0822dfccac6f0ab4d061989e2/260c2/02_iMovie_Neues_Projekt_Film.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/57c819d0822dfccac6f0ab4d061989e2/81c8e/02_iMovie_Neues_Projekt_Film.png 230w", "/en/static/57c819d0822dfccac6f0ab4d061989e2/08a84/02_iMovie_Neues_Projekt_Film.png 460w", "/en/static/57c819d0822dfccac6f0ab4d061989e2/c0255/02_iMovie_Neues_Projekt_Film.png 920w", "/en/static/57c819d0822dfccac6f0ab4d061989e2/302a4/02_iMovie_Neues_Projekt_Film.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/57c819d0822dfccac6f0ab4d061989e2/c0255/02_iMovie_Neues_Projekt_Film.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Enter a name for your project here:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/60bb705bd05c601fa70a06f819d24046/9cea8/03_iMovie_Projekt_benennen.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "14.782608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAwElEQVQI11WMy06DQAAA92AI0APxZBrZ7tZFIooxPMo+2rQUw5ka49H//4wx4eZhkrnMCGvf+LwGvr8WltuNYRhWnHP4cMSfr5ynmXCZ6GxgcI6DHej7nrZt6bqOpmlWH8cRUfofHj4WtqWlfDZIuSOXkrqucT7gLxOtDev4NM1U4y+6PhJHd8RxQpIkRFFElmXkeY54tzO6PvG4r1A7idYapRTGGIrCYJ72FMbwWr2glWJzvyXZZAgh/pGm6dr+AbImWHhL4sJcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60bb705bd05c601fa70a06f819d24046/e4706/03_iMovie_Projekt_benennen.avif 230w", "/en/static/60bb705bd05c601fa70a06f819d24046/d1af7/03_iMovie_Projekt_benennen.avif 460w", "/en/static/60bb705bd05c601fa70a06f819d24046/7f308/03_iMovie_Projekt_benennen.avif 920w", "/en/static/60bb705bd05c601fa70a06f819d24046/39beb/03_iMovie_Projekt_benennen.avif 1278w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/60bb705bd05c601fa70a06f819d24046/a0b58/03_iMovie_Projekt_benennen.webp 230w", "/en/static/60bb705bd05c601fa70a06f819d24046/bc10c/03_iMovie_Projekt_benennen.webp 460w", "/en/static/60bb705bd05c601fa70a06f819d24046/966d8/03_iMovie_Projekt_benennen.webp 920w", "/en/static/60bb705bd05c601fa70a06f819d24046/893d6/03_iMovie_Projekt_benennen.webp 1278w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60bb705bd05c601fa70a06f819d24046/81c8e/03_iMovie_Projekt_benennen.png 230w", "/en/static/60bb705bd05c601fa70a06f819d24046/08a84/03_iMovie_Projekt_benennen.png 460w", "/en/static/60bb705bd05c601fa70a06f819d24046/c0255/03_iMovie_Projekt_benennen.png 920w", "/en/static/60bb705bd05c601fa70a06f819d24046/9cea8/03_iMovie_Projekt_benennen.png 1278w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/60bb705bd05c601fa70a06f819d24046/c0255/03_iMovie_Projekt_benennen.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To change the default settings for your project, you need to open iMovie Settings:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "293px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/34c65133bada066ca032dcd6710dea9b/3cf3e/04_iMovie_Einstellung.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEmklEQVQ4yz2O20+TBxiHv//BuStjYkxYssQtGS4bbEu4WKLZbpcs7MJdbEuMZheaOUccomyAwqYCisgZ5VBaej7QE2copR/0RGn5WgpyGIfSA205qDfP0s7s4sn75vfmffITeh1BqnqUXFe/4lJLmqs9B5Q2Zyhty6CYX6ZeP8ljkwOlZxV9YJNHugla7HM0GKZoMjtR+9awRxM8Nc/QOe5FuKt082v3GLc0G9xUxSkb3OSGYpty7TY9rjW6HFHaJyTk3m00wTi9rjX65zboE9fpm9tg0L/DA0uIWwMiFYPzCD91+vm+WaR+eBl9OEWtzkWDxUfT8CJVKhd1Rh8D/iSa8BHKUDY/NdJhfmojx6hDGa52znGpxc0PHT6E3/RbXFOsUD+6jiqUQeZLMrCQyjO4uP+WFKpQGmUwxz6qYDqfyXxxVMEUt7URftFsUKbfRCi3Jrip2+LZzC7yhTj3tHPUGdzUaEXu6eaoUjmptwZQBJJopCxaKYth+QiZN8Z9nYhWylBjXueuZY9KWxyh0p7kd+M2z6a30IWz+Sb5x3AW9VIa+UICjZTJi/TLh+giB/lbLsvd1aEUtdZ1qu1x7o8kEf4cSVFu2qZ9NoYqmOTBkJe/TR6qNbM02AK0O1ZpGl3K5zmaxyR04Qz6yAGGyAH6cJpa2wZV9gQ1eeFoivKhHVpmtjFGD1AGU8gX9pD5dpH79xjwxxjI7f9nMVSLCXRSGn04i34pRZVlnTv2BH/khNVj+1SYd+kU99BKKeqtPprHl3g8HEC9tM/Qy2NMq0eYVnIcYooeYoxkGVo+pHMqSo9jhbqRbSqHk9SMphBqJzJU2mJ0uHYxrWSR+XZQLMTo92zlG1s3XmNeO8by8hXm1SMMUhrdYhLDYhK1ZxfV3D/UDW+SK3Z/Io3wYDpDzcge3fMxDJEU7dMRupxRZN4tesQ1Wicl2qfCtI6H6JiQ0PhjGBeTqMRNGlUOXtj9VA96qVAvcVsTQmiYyVA3tkefZw/T8j5Nw34aLG7aJkK0jC3SaPXQaHFTbxR5qHUyKG5g9O3QbfVQ3aamUz9F6ZXLlFy4yDellxCaxCyPJuPIvHtYovu0T+ZEATqmluhyhGkdC/DU5qFtJECjwUXfZBjj/Dqt6nH+6hikU27lq7pv+fCLD/j58hWENvcRTxxJFP441pX9/PNzh0Tr2AKNZpGWER8NRiey2RXskTQm3zY6Zxjd1ALaURG1dYovL1ykoKCA8+c/RnjuP6ZV3EfhjzGylsW8nMK6ks7Lc42t0RRDUgLzUgKDfwedaxXVuB+ZeZp+nZ1+jZlPPi3m3ZMnOXXqFEJ/8DXd7jS6UAL7aoqWYQ+to156nRJto146xv08Nbtot3uxheKY3GsMiREUNid3ah/zpL2Xos8+550TJzh9+jSCUnqDPHCIwruL2r9F3+xKHpn4kl5nlJ6ZKC+mI7yYitA/vUyXzcu95l5uVlRzvayCazfK+KjwPGfPnuXMmTMI+sgbcigDWWTuBHJv8j88CeSeJANv6XPHaZtc5aHezdff/cj77xVQXFxMYWEhRUVFlJSUcO7cOf4F+FRugayHhZwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/34c65133bada066ca032dcd6710dea9b/e4706/04_iMovie_Einstellung.avif 230w", "/en/static/34c65133bada066ca032dcd6710dea9b/b1ec4/04_iMovie_Einstellung.avif 293w"],
              "sizes": "(max-width: 293px) 100vw, 293px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/34c65133bada066ca032dcd6710dea9b/a0b58/04_iMovie_Einstellung.webp 230w", "/en/static/34c65133bada066ca032dcd6710dea9b/2b735/04_iMovie_Einstellung.webp 293w"],
              "sizes": "(max-width: 293px) 100vw, 293px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/34c65133bada066ca032dcd6710dea9b/81c8e/04_iMovie_Einstellung.png 230w", "/en/static/34c65133bada066ca032dcd6710dea9b/3cf3e/04_iMovie_Einstellung.png 293w"],
              "sizes": "(max-width: 293px) 100vw, 293px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/34c65133bada066ca032dcd6710dea9b/3cf3e/04_iMovie_Einstellung.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can specify how you want your photos to be placed, how long an individual photo should be displayed and how long the transition between photos should take:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "524px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/14aafd5b9781c983743d0aa30a8e7130/664c8/05_iMovie_Einstellung_Fotodauer.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABvElEQVQoz2VTy26jQBD0T+QQJcZgg4EQOWvxtHkYMGCBgr32RlqttPJlP2D//1RRtYJFkkOJnpqerpruYZImCcIwxHq9huu68DxPYnJlWSLPd/Ildrud5Pi+L3kDhnUURZisVitYlgXHceSr6wZs+wmGYWC5XApnLXU8OzZWLy/QdR2z2Qyqqn6CoiiSO+n7HofDQUC3VVni7XLG8XjE6XTC2+Uniu43/KTBJvRh2bYU1DTtE8hJQdpN0xRRFMILIjT9Bb/+/kP32qNtO/RdA7++4t6M8XB/B0WZyeEx6PBWkFZdz0OaxFhHKZ7SFtmf/3B+uFCmj1A1DcZChW0aMC0Lpml+A1tzK7hYLKRf8/lcNrKixL6qURQFttst4ngLPwgk3mw20niCA+LNkiSRgUynU9i2jQmL7fd7NE0jiZZlIggC2eRVKETRARwKOYK9G6/FIQmCySTatpWBEFRPPp7VEOd5jqqqEMcxsiz7eFq57IlDWmdCXdfirOs6XK9XnM9n6c/Y2SA8Xg+D4ZVvQyFI0DaV2S8KsY90QYeM6YQ8c+iMHIvy6jwrDr++p0FgECHG8df18LApyp/kHeeZVnK8eO0HAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14aafd5b9781c983743d0aa30a8e7130/e4706/05_iMovie_Einstellung_Fotodauer.avif 230w", "/en/static/14aafd5b9781c983743d0aa30a8e7130/d1af7/05_iMovie_Einstellung_Fotodauer.avif 460w", "/en/static/14aafd5b9781c983743d0aa30a8e7130/c166f/05_iMovie_Einstellung_Fotodauer.avif 524w"],
              "sizes": "(max-width: 524px) 100vw, 524px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/14aafd5b9781c983743d0aa30a8e7130/a0b58/05_iMovie_Einstellung_Fotodauer.webp 230w", "/en/static/14aafd5b9781c983743d0aa30a8e7130/bc10c/05_iMovie_Einstellung_Fotodauer.webp 460w", "/en/static/14aafd5b9781c983743d0aa30a8e7130/1c5aa/05_iMovie_Einstellung_Fotodauer.webp 524w"],
              "sizes": "(max-width: 524px) 100vw, 524px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14aafd5b9781c983743d0aa30a8e7130/81c8e/05_iMovie_Einstellung_Fotodauer.png 230w", "/en/static/14aafd5b9781c983743d0aa30a8e7130/08a84/05_iMovie_Einstellung_Fotodauer.png 460w", "/en/static/14aafd5b9781c983743d0aa30a8e7130/664c8/05_iMovie_Einstellung_Fotodauer.png 524w"],
              "sizes": "(max-width: 524px) 100vw, 524px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/14aafd5b9781c983743d0aa30a8e7130/664c8/05_iMovie_Einstellung_Fotodauer.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in the iMovie interface you can now click on `}<strong parentName="p">{`Import Media`}</strong>{` to add your photos to the project:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bfe22402b82e9ae822db7a58030054f2/e8d6f/06_iMovie_Medien_importieren.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABS0lEQVQoz6WSS2/CMBCEfUEBDhwQgkMeEEgxiCoJ5EEeElC1Z1q1PfT//5GpZitLbqH00MOnjHezY6/XqilXeHxo8f76gvP5GUVRIM9zVFUlOo5jNE2Duq5FZ1mG3W4nbLdbIUkSpGmKw+EAFdUf8LM3eLrEWt9hsVggCAIsl0tEUYQwDDGdTuF5HkajERzHQbfb/Uan08FgMIDv+1BJ9YQobuGFGkHgSzGhqdE0nc/nGA6HUEpdpdfrYTabQTVVgfh+Dd9zpZBGWmtpzzYktwz7/f6XYV4U0HolLTFAg81mI3djNmD8L0O2zlpl35M5Cdszmmb8hzHXdTEejwVuRCaTicD74/+KU+NUOVHDzzWnbKZelqXANaE2+bZtoUyC7Pf7qxgTo+2YXUet7EK+o9PpJN/j8XiByduxm4Z8vDz+b1zL2/UXhv+Fhp+wekeCkQx2vwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfe22402b82e9ae822db7a58030054f2/e4706/06_iMovie_Medien_importieren.avif 230w", "/en/static/bfe22402b82e9ae822db7a58030054f2/d1af7/06_iMovie_Medien_importieren.avif 460w", "/en/static/bfe22402b82e9ae822db7a58030054f2/7f308/06_iMovie_Medien_importieren.avif 920w", "/en/static/bfe22402b82e9ae822db7a58030054f2/ad05b/06_iMovie_Medien_importieren.avif 1273w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bfe22402b82e9ae822db7a58030054f2/a0b58/06_iMovie_Medien_importieren.webp 230w", "/en/static/bfe22402b82e9ae822db7a58030054f2/bc10c/06_iMovie_Medien_importieren.webp 460w", "/en/static/bfe22402b82e9ae822db7a58030054f2/966d8/06_iMovie_Medien_importieren.webp 920w", "/en/static/bfe22402b82e9ae822db7a58030054f2/179c4/06_iMovie_Medien_importieren.webp 1273w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfe22402b82e9ae822db7a58030054f2/81c8e/06_iMovie_Medien_importieren.png 230w", "/en/static/bfe22402b82e9ae822db7a58030054f2/08a84/06_iMovie_Medien_importieren.png 460w", "/en/static/bfe22402b82e9ae822db7a58030054f2/c0255/06_iMovie_Medien_importieren.png 920w", "/en/static/bfe22402b82e9ae822db7a58030054f2/e8d6f/06_iMovie_Medien_importieren.png 1273w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bfe22402b82e9ae822db7a58030054f2/c0255/06_iMovie_Medien_importieren.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then select the folder that contains your photos:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8dc45bc053f089531340dbd8c827f71c/b5cea/07_iMovie_Auswahl_importieren.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+UlEQVQoz52MS0/CQBSF50dg3GDnySOEgH0IQltgJKai+Ig7qyF25f//BcfMlA513LH45p5z7rlD4vUjprdbDMLcMplv0b/OkWQPWOg9JjON8Y22vj/NMJ7dWR8uCwyjFYbhCmFaIM52GCUaRG4q9NYVLuISnah0szv/gsq/wdMDLpMSdHmo9w2x4ROd6MNpAxH0CoIHUIL+QfLA5pJTh99Rgv3LiJASUilIaZCwvuGY19lJC9dTrX69J0KI0wemePTt3NcN/s5AzKOUcjS+nfvav6np2UkYYzgXSikoDcBo18JZAKK1xrm8vjyjeHrDYlch3f8gu3/HLxQp8XcTJFOJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8dc45bc053f089531340dbd8c827f71c/e4706/07_iMovie_Auswahl_importieren.avif 230w", "/en/static/8dc45bc053f089531340dbd8c827f71c/d1af7/07_iMovie_Auswahl_importieren.avif 460w", "/en/static/8dc45bc053f089531340dbd8c827f71c/7f308/07_iMovie_Auswahl_importieren.avif 920w", "/en/static/8dc45bc053f089531340dbd8c827f71c/0c10b/07_iMovie_Auswahl_importieren.avif 1140w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8dc45bc053f089531340dbd8c827f71c/a0b58/07_iMovie_Auswahl_importieren.webp 230w", "/en/static/8dc45bc053f089531340dbd8c827f71c/bc10c/07_iMovie_Auswahl_importieren.webp 460w", "/en/static/8dc45bc053f089531340dbd8c827f71c/966d8/07_iMovie_Auswahl_importieren.webp 920w", "/en/static/8dc45bc053f089531340dbd8c827f71c/c89f9/07_iMovie_Auswahl_importieren.webp 1140w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8dc45bc053f089531340dbd8c827f71c/81c8e/07_iMovie_Auswahl_importieren.png 230w", "/en/static/8dc45bc053f089531340dbd8c827f71c/08a84/07_iMovie_Auswahl_importieren.png 460w", "/en/static/8dc45bc053f089531340dbd8c827f71c/c0255/07_iMovie_Auswahl_importieren.png 920w", "/en/static/8dc45bc053f089531340dbd8c827f71c/b5cea/07_iMovie_Auswahl_importieren.png 1140w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8dc45bc053f089531340dbd8c827f71c/c0255/07_iMovie_Auswahl_importieren.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your photos are now displayed in the Media Panel. Select all photos and drag them into the project timeline:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/05b916314a4e620aae4680e4544f7388/e8d6f/08_iMovie_Fotos_Drag_and_Drop.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQElEQVQoz4WRXU8TQRSG+y8E+kVbaj92tx/b7e52t91SJCigGGqMUUy8UBpIbSiKFoqAICCaFI0g9kINSmLir3zMLLRqvPDiyZw58553Zs7xqKqEZdtMTU1TqYyRTmdIpTNk1RxZVUVRFDRNI5/PI8syuVyOTDqNosgosuzGUjJJIpHAMAw8um4gSRJSMo5t6WQzMooUo2BqyFKC4eFhwuEwPp8Pv99Pc/EB+9st2uvPeL6+ytLaKgtPm8xWb+L1evEUCgVi8TiVkkrn3T7NVoPl+l2Ouu+5fWeGgUsDRKNRgsEgIyMjbL9s8e3HKZ/Ozjj68ZP9r9/Z+XxKvf3C1XhM0yQavYxlyOzutqk1HlJ7VOVVZ48bt6YZHBh0jYKBAKFIhPnHi3ROjtk97rLx8QvLh12WDrvMPWn/NgyFwliGxOs3G9SbNRrzVbY/vOVaVRgOEIlEXLHf76N8dYLlrR3WOsesdLrUD06YPzhhttEiEAjiEbf7fH5sS6W9s8ncYo2Ve5Ns7m0xMTOJd2jI/XIoFHKJxWMkU2nMUhm7Mk7GsFDyJnIu7/bbI6Ym0A2d8tg45ugVrls690cdckUHLaci+iwmKKYtyGYyZNKpc1IKmprt69yhCMTX85qGLgp0g4V0lAkti2aaGLruGvYQ2h7n9Vbfw+1hT+Q4DmXHwXIcZko20yULW+TKDqVSyUVoBL249/oefxnatk2xWKRo2xSKJQoiLp7nxFn//GIv1l79P4YCXdf7GBfo/+FPM2H4CxDfhRIMWIiWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05b916314a4e620aae4680e4544f7388/e4706/08_iMovie_Fotos_Drag_and_Drop.avif 230w", "/en/static/05b916314a4e620aae4680e4544f7388/d1af7/08_iMovie_Fotos_Drag_and_Drop.avif 460w", "/en/static/05b916314a4e620aae4680e4544f7388/7f308/08_iMovie_Fotos_Drag_and_Drop.avif 920w", "/en/static/05b916314a4e620aae4680e4544f7388/ad05b/08_iMovie_Fotos_Drag_and_Drop.avif 1273w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/05b916314a4e620aae4680e4544f7388/a0b58/08_iMovie_Fotos_Drag_and_Drop.webp 230w", "/en/static/05b916314a4e620aae4680e4544f7388/bc10c/08_iMovie_Fotos_Drag_and_Drop.webp 460w", "/en/static/05b916314a4e620aae4680e4544f7388/966d8/08_iMovie_Fotos_Drag_and_Drop.webp 920w", "/en/static/05b916314a4e620aae4680e4544f7388/179c4/08_iMovie_Fotos_Drag_and_Drop.webp 1273w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05b916314a4e620aae4680e4544f7388/81c8e/08_iMovie_Fotos_Drag_and_Drop.png 230w", "/en/static/05b916314a4e620aae4680e4544f7388/08a84/08_iMovie_Fotos_Drag_and_Drop.png 460w", "/en/static/05b916314a4e620aae4680e4544f7388/c0255/08_iMovie_Fotos_Drag_and_Drop.png 920w", "/en/static/05b916314a4e620aae4680e4544f7388/e8d6f/08_iMovie_Fotos_Drag_and_Drop.png 1273w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/05b916314a4e620aae4680e4544f7388/c0255/08_iMovie_Fotos_Drag_and_Drop.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the iMovie menu you can now go to `}<strong parentName="p">{`Share`}</strong>{` in `}<strong parentName="p">{`Ablage`}</strong>{` and export your movie as a video file:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f0f48eff6bd3425c393d9da11363245/e8e04/10_iMovie_Export_16s.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC5ElEQVQoz33OfU9bZRjH8fMSdIlCiwzqximlhSGj57S0Sp/O6XFkMXRk1q3giG1X2gErDRzC6ACJ4mJGFx6kMMqT5WHDSVWyxRiyoSa+rK/Z2UyMUf/45Lqv331fV25hcHaR4ne/8ejpCbvHJxw8fc50YYWPE4NEk8PE0iOk9Wn6h3R60zmupbJEE8NEE0NcT2WJ9CboutpHNJnl9uQswqd9MbZ3vmV1fZON7TIHh0ck4gnePvMGlrO11JqrqXunhrfOvInDJnIp7EcJeAgHOgj5PXzg8yI530N8t476s7UIiZtpCktFVktbPK4cc3T8jMLiErH+fmw2G+fPNyCKVkwmM5HuLg721zh8XGLv0SbL2yW+XCsyu7SA19dJVVU1wmfxOOW9PSo/HHFy+oLnv56yu1dmZFynrt5CVbUJi8WCuaYGtSvMYrHAwsMic2sbTK2UyM0vMfrgGzoCQUzVVQix5AgzxWPmNn/h/v7v3N//g+VyhS/ufU7skx6uRi5ja2zEbDbj9LjRZ2eYWymSX17nzvIGt+dXycyvIvlDmF8ujPbF+XqlzOLWE3Z+OqX84wvWD3+mUH7GvbXvmXmwg735AmaTiXPn6nH5A/T0x7meyRJJDnL5RooPb6RoanO+/mFvLw9LG2yVd9l9csRhpUL+qwU83QO0dF7B4f2I1rZ27PYm7HY7VlFEbGig2WGn0SpiFRtoarTS7HDQ2tqK4PW+j6JqKGGNkBpG1S7h9XTQ1mJDamtBungBWZaRJAmnU0KSZaM3zpKM7HLhcrmNzO12I6hKkGCgk1DAZwgEOlGUIJoWRtNUNE1BU4OoapCwUUMof2NkyitaWEEYHp/i1ugdhvW7DOl5BsbuktKnuTk2xcBYnrSeJ6NPkh6dMGTGJknlJkjmJkjkJrmVGyeTG2cgq5ObmEJw+iI4fd04/S/rK+2GK0bW7v+r7zbupH+8M2ZfkwM9CF6Pmw63/K88/5H/nz8BRRfsX/MYn4sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f0f48eff6bd3425c393d9da11363245/e4706/10_iMovie_Export_16s.avif 230w", "/en/static/2f0f48eff6bd3425c393d9da11363245/d1af7/10_iMovie_Export_16s.avif 460w", "/en/static/2f0f48eff6bd3425c393d9da11363245/7f308/10_iMovie_Export_16s.avif 920w", "/en/static/2f0f48eff6bd3425c393d9da11363245/9f90b/10_iMovie_Export_16s.avif 1270w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f0f48eff6bd3425c393d9da11363245/a0b58/10_iMovie_Export_16s.webp 230w", "/en/static/2f0f48eff6bd3425c393d9da11363245/bc10c/10_iMovie_Export_16s.webp 460w", "/en/static/2f0f48eff6bd3425c393d9da11363245/966d8/10_iMovie_Export_16s.webp 920w", "/en/static/2f0f48eff6bd3425c393d9da11363245/3979e/10_iMovie_Export_16s.webp 1270w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f0f48eff6bd3425c393d9da11363245/81c8e/10_iMovie_Export_16s.png 230w", "/en/static/2f0f48eff6bd3425c393d9da11363245/08a84/10_iMovie_Export_16s.png 460w", "/en/static/2f0f48eff6bd3425c393d9da11363245/c0255/10_iMovie_Export_16s.png 920w", "/en/static/2f0f48eff6bd3425c393d9da11363245/e8e04/10_iMovie_Export_16s.png 1270w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f0f48eff6bd3425c393d9da11363245/c0255/10_iMovie_Export_16s.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the quality settings for the video here - `}<strong parentName="p">{`Caution`}</strong>{`: at the bottom left you can see the size the file will have at the end. You can see that the file can become very large with maximum quality:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "744px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9dff1f3e3c52a02e2f3ad8040f483691/cab8c/11_iMovie_Export_Einstellung.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEUlEQVQoz42S3WvTUBjG81eJ4I13gn+At0OEgSgOGUqp23ClODbn2qptWZattqTaq+G8FLxpL1Zs2VzpB11pmzQ5+WiTNFnn9SPvGZkWFLz48ZzknDx53vO+gqZpIEaj3xquCZ0xDIdDdLtdDAYD9Pt9Dq1VVb0+R9CzYFkWGNNh2xYsy+Rq2yZfm6YBXRvBtm1Uq1WUy2WuRKVSQbvdxtX3jKvruhBKpRLisQiOPsv4cpjjfPwkYbewj+SBiNX4Gmq17zAMA81mkyft9Xo8jaIoPD0pJXQcB0JBLmDpyQPIxV1IuQzSORGbooiX6QyiqXd4tB7Dcb2Oy9kMjUaDQ8ZkSMmm0ynH87wrw3xRxsLifezlRaQkERvSB8T28oimJSwns3gY38LxySkufJ8bdTodnpKSmabJTUJ4ydLBPm7fvYP17S2sJZKIJNJ4tpPF09fvsfgqhXuRGMq1Exi6jh9nZ2i1WtyUklJzyGTOsCjncePWTTyOrmDlTRIvEhk838ly0+XtDBZWN1Cpn+JydgHHdXlpBJUZmpGGCBS7d36OPo0BtZ8xaMyAqjMoIw1Dlbo8nksyXyapew03pLEIgoAfoG4yXcfYtuH7Pvx/JPkTz3Uw9Rx4ngOBukVDShtkTvdCF04dpHeTyeSvyYhwzxq70C0Phu1CCP8SboZ39D9miqLCGRv41gjw9utPHNUC/AJ5MvkzrOLwyAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9dff1f3e3c52a02e2f3ad8040f483691/e4706/11_iMovie_Export_Einstellung.avif 230w", "/en/static/9dff1f3e3c52a02e2f3ad8040f483691/d1af7/11_iMovie_Export_Einstellung.avif 460w", "/en/static/9dff1f3e3c52a02e2f3ad8040f483691/a90be/11_iMovie_Export_Einstellung.avif 744w"],
              "sizes": "(max-width: 744px) 100vw, 744px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9dff1f3e3c52a02e2f3ad8040f483691/a0b58/11_iMovie_Export_Einstellung.webp 230w", "/en/static/9dff1f3e3c52a02e2f3ad8040f483691/bc10c/11_iMovie_Export_Einstellung.webp 460w", "/en/static/9dff1f3e3c52a02e2f3ad8040f483691/43142/11_iMovie_Export_Einstellung.webp 744w"],
              "sizes": "(max-width: 744px) 100vw, 744px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9dff1f3e3c52a02e2f3ad8040f483691/81c8e/11_iMovie_Export_Einstellung.png 230w", "/en/static/9dff1f3e3c52a02e2f3ad8040f483691/08a84/11_iMovie_Export_Einstellung.png 460w", "/en/static/9dff1f3e3c52a02e2f3ad8040f483691/cab8c/11_iMovie_Export_Einstellung.png 744w"],
              "sizes": "(max-width: 744px) 100vw, 744px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9dff1f3e3c52a02e2f3ad8040f483691/cab8c/11_iMovie_Export_Einstellung.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can give the video file a name by clicking `}<strong parentName="p">{`Next`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "745px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c8aa6ac524cdb503b213f3be19c4ef59/7e509/12_iMovie_Projekt_sichern.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVQoz4WSW08TURRG+7t8UcMfIEZiTCSUYKogRSAtl1KugbQOVaFcpIVCYQYMEvRFXxAEJBIfJBo0YGx6b2em0wsQ8XmZM7EEDNGHL2dnn7PX/rLPtmQyGbLZLOl0BlVViUQiyLKMLC8QlhWmlFXGw8uMzy4RDCsE5hSm5xWmQvN82ttD13XS6bSpVCqFRVU1UqkkmpbFMHIcHHyjubXZVJPDSWOPD3vfCPZuiZaObhwuN/b2fhpa21h7u06pVDQN5XI5SqUSlsVFBXfnQ1ZXZnm1MoMc9lNXb6P2vo26B/W0u9p4JHnxDUsMeSRcg34aeudodnnZ3npHJpMlFouZDguFAhZlScFur0NRgoTmA/gmx6iyWrlRU0PlnWqqaqx0uLuRJC+Ozj4qG59xyxHmtt3Hx90dTk9/mc6ETODC0iLVd60EwwFGZ4IMBkI4pRGahoa51++htrOHl6/f8HV/n431dTY31ni/tcHO9iaxePwMJFQsFrHMzIa4UnEdt9dDl+8JzsdjOJ9O0eKbxObxc7NjgN0v+xQMg8PD70SjUaKxGD8iERKJhAm5AHyx/JyrFddo7e1jwD9B/3gQt3+aLv80rtEgtgEfH/Y+8/PkhHw+fwYQ59+xCRSPstkMYn00XSdnGBhGHj1noGo6qqZdAP1PFgESP3R8fIxhGCQTCeLxuLmTR0dH5owuc3KZTKCAJZNJMyGWVMQip2mamcv/w41wLnRhhmV6+aK8Audz5cLzxSIWzc8a/xnLbwRz067dspVUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8aa6ac524cdb503b213f3be19c4ef59/e4706/12_iMovie_Projekt_sichern.avif 230w", "/en/static/c8aa6ac524cdb503b213f3be19c4ef59/d1af7/12_iMovie_Projekt_sichern.avif 460w", "/en/static/c8aa6ac524cdb503b213f3be19c4ef59/57346/12_iMovie_Projekt_sichern.avif 745w"],
              "sizes": "(max-width: 745px) 100vw, 745px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c8aa6ac524cdb503b213f3be19c4ef59/a0b58/12_iMovie_Projekt_sichern.webp 230w", "/en/static/c8aa6ac524cdb503b213f3be19c4ef59/bc10c/12_iMovie_Projekt_sichern.webp 460w", "/en/static/c8aa6ac524cdb503b213f3be19c4ef59/06157/12_iMovie_Projekt_sichern.webp 745w"],
              "sizes": "(max-width: 745px) 100vw, 745px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8aa6ac524cdb503b213f3be19c4ef59/81c8e/12_iMovie_Projekt_sichern.png 230w", "/en/static/c8aa6ac524cdb503b213f3be19c4ef59/08a84/12_iMovie_Projekt_sichern.png 460w", "/en/static/c8aa6ac524cdb503b213f3be19c4ef59/7e509/12_iMovie_Projekt_sichern.png 745w"],
              "sizes": "(max-width: 745px) 100vw, 745px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c8aa6ac524cdb503b213f3be19c4ef59/7e509/12_iMovie_Projekt_sichern.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The resulting file can now be post-processed if desired. In the example the speed of the video is adjusted to shorten the length of the video from 16s to 6s:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/07e0f223f0e5df62179879b56d615b7a/d0fa6/13_iMovie_Tempo_angepasst.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB8UlEQVQoz22RW08TURSF519ImJ65tJ1OZ9r0hs6lF8pAC6RViD6pERNSVBBRiFhQTIwYTSTGKGBiNSHGB//mZ87gEII8fNmTWeuss/c+Si6Xw7ZtgsCnWCxiGAau61Iul3Ech0wmg5u3qeRt/GqFWrGIZZrYukbTzWNmsmiaFmfIc0qj0aBUKhGEIYu3bhLWg9iQzWZJp9MYZhonbRDZaWbLBe62rvHoxgwL810W+/P0enN4E1V0XUc3DJRmsxl31Z3t8O7TAQ/W19CEODX8QwarQnBlbIzhxoA/v0eMfp1wdHLC15/febb5BCFSCCFOO8w7DoYuKOQt7Fw2vuA8cmxd09E0wdK92xweH3LwbcT+0Q/eHI8YbG3HU0mUarWKaZqoaoqUEDGJmCBD5X91fJyJqzUGa495urPHwxd7LG/v0buzRCqVQlVVFLkry7JIHkfWixQKhTNdjp+1LKq1GuVKhZxt47hu/CBSV8IwxPM8PN+Pq+/7MZ53WqUu91yv1+PvhCAIzpCaXF273UZpNhrMdqaZjtp0ZiKiqUmiaIrpaCo2+BcuSjgfmHhksDI/1+Xj5y9s7L5m+PY9Wy9fsTkcsrP/gfXnu/8dvIxEk10qYatDe3GVen9Avb9C4/oKzf4yrYVVJnv3z8zngy8jCfwLLWdPwcstKEEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/07e0f223f0e5df62179879b56d615b7a/e4706/13_iMovie_Tempo_angepasst.avif 230w", "/en/static/07e0f223f0e5df62179879b56d615b7a/d1af7/13_iMovie_Tempo_angepasst.avif 460w", "/en/static/07e0f223f0e5df62179879b56d615b7a/7f308/13_iMovie_Tempo_angepasst.avif 920w", "/en/static/07e0f223f0e5df62179879b56d615b7a/216d5/13_iMovie_Tempo_angepasst.avif 1263w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/07e0f223f0e5df62179879b56d615b7a/a0b58/13_iMovie_Tempo_angepasst.webp 230w", "/en/static/07e0f223f0e5df62179879b56d615b7a/bc10c/13_iMovie_Tempo_angepasst.webp 460w", "/en/static/07e0f223f0e5df62179879b56d615b7a/966d8/13_iMovie_Tempo_angepasst.webp 920w", "/en/static/07e0f223f0e5df62179879b56d615b7a/879a2/13_iMovie_Tempo_angepasst.webp 1263w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/07e0f223f0e5df62179879b56d615b7a/81c8e/13_iMovie_Tempo_angepasst.png 230w", "/en/static/07e0f223f0e5df62179879b56d615b7a/08a84/13_iMovie_Tempo_angepasst.png 460w", "/en/static/07e0f223f0e5df62179879b56d615b7a/c0255/13_iMovie_Tempo_angepasst.png 920w", "/en/static/07e0f223f0e5df62179879b56d615b7a/d0fa6/13_iMovie_Tempo_angepasst.png 1263w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/07e0f223f0e5df62179879b56d615b7a/c0255/13_iMovie_Tempo_angepasst.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a8052a5ae59cd3a5497fe57e99415f62/b9024/14_iMovie_Erledigt_6s.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABe0lEQVQoz12RXWvUQBSG8zt2k83HJKv5ntlNmsSsW9N779QfYC+EFa+KiGBFpLZSpHXbohREFAXxVz4ys2yFXrwM83LeZ86ZYymlGIaBxWLBdHqHLM+pqor5fE6SJMRxTFGUpGnKrNohywvCKCIQIVleEkVThBBIKem6DqsoCvK8YNjbo64rPM/F932CIDDyPA/P80nThFy1NG3LsLxH37fsPrjPctmTprHJhGGIpel933G6PuPdyRFKSWzbNiDX/Q8fjUakyV3O1h/5/fcnv/784PL7Ny6ur1it9hmPx0wmEyw9iudOkGVGKQsT1qDbQB2I04SDN6/5cPmFw4tr3q6/cvj5iqfPVzi2vQGGQpiObNu5eUWDttJg3/dMje+7PHzyiJdHJxwcn/Pi/SdenZ7zeP+ZyTqOg6U70CE9+vbftl3pU/tRFBm4CAJEKMhKyWJ3YNZ05GpOqZSp1bLqujZbva2t3zSN2d7mvvFmSqGkvNFOXdO2LX3f8w/RGOTAxeWueQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a8052a5ae59cd3a5497fe57e99415f62/e4706/14_iMovie_Erledigt_6s.avif 230w", "/en/static/a8052a5ae59cd3a5497fe57e99415f62/d1af7/14_iMovie_Erledigt_6s.avif 460w", "/en/static/a8052a5ae59cd3a5497fe57e99415f62/7f308/14_iMovie_Erledigt_6s.avif 920w", "/en/static/a8052a5ae59cd3a5497fe57e99415f62/9be27/14_iMovie_Erledigt_6s.avif 1203w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a8052a5ae59cd3a5497fe57e99415f62/a0b58/14_iMovie_Erledigt_6s.webp 230w", "/en/static/a8052a5ae59cd3a5497fe57e99415f62/bc10c/14_iMovie_Erledigt_6s.webp 460w", "/en/static/a8052a5ae59cd3a5497fe57e99415f62/966d8/14_iMovie_Erledigt_6s.webp 920w", "/en/static/a8052a5ae59cd3a5497fe57e99415f62/bf9e6/14_iMovie_Erledigt_6s.webp 1203w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a8052a5ae59cd3a5497fe57e99415f62/81c8e/14_iMovie_Erledigt_6s.png 230w", "/en/static/a8052a5ae59cd3a5497fe57e99415f62/08a84/14_iMovie_Erledigt_6s.png 460w", "/en/static/a8052a5ae59cd3a5497fe57e99415f62/c0255/14_iMovie_Erledigt_6s.png 920w", "/en/static/a8052a5ae59cd3a5497fe57e99415f62/b9024/14_iMovie_Erledigt_6s.png 1203w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a8052a5ae59cd3a5497fe57e99415f62/c0255/14_iMovie_Erledigt_6s.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "335px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6c0678402cc253fee33a23ff4e849eed/c0051/15_iMovie_iMovie_Benachrichtigung.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "17.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAwUlEQVQI1y2ObU6DQAAFuf9FPEE1MYY0KK18WLBbC0thtyKyG2HZ3mCMxF8vk8kkL6jUhKwdZ/XJ00vKNjvyuD+wec64jxJS0TD8eL6sQxvHxXqk8TTWo+xCbx3lhyQ7nunMTBA+9GzuNN33SBhnRLkgzAXxqV13V1bEb4IoLUiEpDELtb0h7Y3WONQ40dsZPU4rB80wczUOqQb2B8HuvSKR1zWqzcLFLmi7oIyj+39XmT/naa2nlJrX4sQ2Lcil5hd3StUWDl1kYQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c0678402cc253fee33a23ff4e849eed/e4706/15_iMovie_iMovie_Benachrichtigung.avif 230w", "/en/static/6c0678402cc253fee33a23ff4e849eed/1c511/15_iMovie_iMovie_Benachrichtigung.avif 335w"],
              "sizes": "(max-width: 335px) 100vw, 335px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6c0678402cc253fee33a23ff4e849eed/a0b58/15_iMovie_iMovie_Benachrichtigung.webp 230w", "/en/static/6c0678402cc253fee33a23ff4e849eed/89783/15_iMovie_iMovie_Benachrichtigung.webp 335w"],
              "sizes": "(max-width: 335px) 100vw, 335px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c0678402cc253fee33a23ff4e849eed/81c8e/15_iMovie_iMovie_Benachrichtigung.png 230w", "/en/static/6c0678402cc253fee33a23ff4e849eed/c0051/15_iMovie_iMovie_Benachrichtigung.png 335w"],
              "sizes": "(max-width: 335px) 100vw, 335px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6c0678402cc253fee33a23ff4e849eed/c0051/15_iMovie_iMovie_Benachrichtigung.png",
              "alt": "iMovie Timelapse",
              "title": "iMovie Timelapse",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      